function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var Events = function () {
    function Events() {
        _classCallCheck(this, Events);
        this.events = {};
        this.audioEvents = [
            'abort',
            'canplay',
            'canplaythrough',
            'durationchange',
            'emptied',
            'ended',
            'error',
            'loadeddata',
            'loadedmetadata',
            'loadstart',
            'mozaudioavailable',
            'pause',
            'play',
            'playing',
            'progress',
            'ratechange',
            'seeked',
            'seeking',
            'stalled',
            'suspend',
            'timeupdate',
            'volumechange',
            'waiting'
        ];
        this.playerEvents = [
            'destroy',
            'listshow',
            'listhide',
            'listadd',
            'listremove',
            'listswitch',
            'listclear',
            'noticeshow',
            'noticehide',
            'lrcshow',
            'lrchide'
        ];
    }
    _createClass(Events, [
        {
            key: 'on',
            value: function on(name, callback) {
                if (this.type(name) && typeof callback === 'function') {
                    if (!this.events[name]) {
                        this.events[name] = [];
                    }
                    this.events[name].push(callback);
                }
            }
        },
        {
            key: 'trigger',
            value: function trigger(name, data) {
                if (this.events[name] && this.events[name].length) {
                    for (var i = 0; i < this.events[name].length; i++) {
                        this.events[name][i](data);
                    }
                }
            }
        },
        {
            key: 'type',
            value: function type(name) {
                if (this.playerEvents.indexOf(name) !== -1) {
                    return 'player';
                } else if (this.audioEvents.indexOf(name) !== -1) {
                    return 'audio';
                }
                console.error('Unknown event name: '.concat(name));
                return null;
            }
        }
    ]);
    return Events;
}();
export default Events;