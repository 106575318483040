function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import Promise from 'promise-polyfill';
import utils from './utils';
import Icons from './icons';
import handleOption from './options';
import Template from './template';
import Bar from './bar';
import Storage from './storage';
import Lrc from './lrc';
import Controller from './controller';
import Timer from './timer';
import Events from './events';
import List from './list';
var instances = [];
var APlayer = function () {
    function APlayer(options) {
        _classCallCheck(this, APlayer);
        this.options = handleOption(options);
        this.container = this.options.container;
        this.paused = true;
        this.playedPromise = Promise.resolve();
        this.mode = 'normal';
        this.randomOrder = utils.randomOrder(this.options.audio.length);
        this.container.classList.add('aplayer');
        if (this.options.lrcType && !this.options.fixed) {
            this.container.classList.add('aplayer-withlrc');
        }
        if (this.options.audio.length > 1) {
            this.container.classList.add('aplayer-withlist');
        }
        if (utils.isMobile) {
            this.container.classList.add('aplayer-mobile');
        }
        this.arrow = this.container.offsetWidth <= 300;
        if (this.arrow) {
            this.container.classList.add('aplayer-arrow');
        }
        if (this.options.lrcType === 2 || this.options.lrcType === true) {
            var lrcEle = this.container.getElementsByClassName('aplayer-lrc-content');
            for (var i = 0; i < lrcEle.length; i++) {
                if (this.options.audio[i]) {
                    this.options.audio[i].lrc = lrcEle[i].innerHTML;
                }
            }
        }
        this.template = new Template({
            container: this.container,
            options: this.options,
            randomOrder: this.randomOrder
        });
        if (this.options.fixed) {
            this.container.classList.add('aplayer-fixed');
            this.template.body.style.width = this.template.body.offsetWidth - 18 + 'px';
        }
        if (this.options.mini) {
            this.setMode('mini');
            this.template.info.style.display = 'block';
        }
        if (this.template.info.offsetWidth < 200) {
            this.template.time.classList.add('aplayer-time-narrow');
        }
        if (this.options.lrcType) {
            this.lrc = new Lrc({
                container: this.template.lrc,
                async: this.options.lrcType === 3,
                player: this
            });
        }
        this.events = new Events();
        this.storage = new Storage(this);
        this.bar = new Bar(this.template);
        this.controller = new Controller(this);
        this.timer = new Timer(this);
        this.list = new List(this);
        this.initAudio();
        this.bindEvents();
        if (this.options.order === 'random') {
            this.list['switch'](this.randomOrder[0]);
        } else {
            this.list['switch'](0);
        }
        var initPlayer = localStorage.getItem('isPlayer');
        if (initPlayer !== null) {
            initPlayer = JSON.parse(initPlayer);
            if (location.href === initPlayer.page && initPlayer.play && initPlayer.status === 'unload') {
                this.currentTime = initPlayer.time;
                initPlayer.play = false;
                initPlayer.status = 'play';
                initPlayer.index !== this.list.index && this.list['switch'](initPlayer.index);
                this.audio.currentTime = this.currentTime;
                localStorage.setItem('isPlayer', JSON.stringify(initPlayer));
                this.options.autoplay = true;
            }
        }
        if (this.options.autoplay) {
            this.play();
        }
        instances.push(this);
        this.isCloseWindowPage();
    }
    _createClass(APlayer, [
        {
            key: 'initAudio',
            value: function initAudio() {
                var _this2 = this;
                this.audio = document.createElement('audio');
                this.audio.preload = this.options.preload;
                var _loop = function _loop(i) {
                    _this2.audio.addEventListener(_this2.events.audioEvents[i], function (e) {
                        _this2.events.trigger(_this2.events.audioEvents[i], e);
                    });
                };
                for (var i = 0; i < this.events.audioEvents.length; i++) {
                    _loop(i);
                }
                this.volume(this.storage.get('volume'), true);
            }
        },
        {
            key: 'bindEvents',
            value: function bindEvents() {
                var _this3 = this;
                this.on('play', function () {
                    if (_this3.paused) {
                        _this3.setUIPlaying();
                    }
                });
                this.on('pause', function () {
                    if (!_this3.paused) {
                        _this3.setUIPaused();
                    }
                });
                this.on('timeupdate', function () {
                    if (!_this3.disableTimeupdate) {
                        _this3.bar.set('played', _this3.audio.currentTime / _this3.duration, 'width');
                        _this3.lrc && _this3.lrc.update();
                        var currentTime = utils.secondToTime(_this3.audio.currentTime);
                        if (_this3.template.ptime.innerHTML !== currentTime) {
                            _this3.template.ptime.innerHTML = currentTime;
                        }
                    }
                });
                this.on('durationchange', function () {
                    if (_this3.duration !== 1) {
                        _this3.template.dtime.innerHTML = utils.secondToTime(_this3.duration);
                    }
                });
                this.on('loadedmetadata', function () {
                    _this3.seek(0);
                    if (!_this3.paused) {
                        _this3.audio.play();
                    }
                });
                this.on('canplay', function () {
                    var percentage = _this3.audio.buffered.length ? _this3.audio.buffered.end(_this3.audio.buffered.length - 1) / _this3.duration : 0;
                    _this3.bar.set('loaded', percentage, 'width');
                });
                this.on('progress', function () {
                    var percentage = _this3.audio.buffered.length ? _this3.audio.buffered.end(_this3.audio.buffered.length - 1) / _this3.duration : 0;
                    _this3.bar.set('loaded', percentage, 'width');
                });
                var skipTime;
                this.on('error', function () {
                    if (_this3.list.audios.length > 1) {
                        _this3.notice('An audio error has occurred, player will skip forward in 2 seconds.');
                        skipTime = setTimeout(function () {
                            _this3.skipForward();
                            if (!_this3.paused) {
                                _this3.play();
                            }
                        }, 2000);
                    } else if (_this3.list.audios.length === 1) {
                        _this3.notice('An audio error has occurred.');
                    }
                });
                this.events.on('listswitch', function () {
                    skipTime && clearTimeout(skipTime);
                });
                this.on('ended', function () {
                    if (_this3.options.loop === 'none') {
                        if (_this3.options.order === 'list') {
                            if (_this3.list.index < _this3.list.audios.length - 1) {
                                _this3.list['switch']((_this3.list.index + 1) % _this3.list.audios.length);
                                _this3.play();
                            } else {
                                _this3.list['switch']((_this3.list.index + 1) % _this3.list.audios.length);
                                _this3.pause();
                            }
                        } else if (_this3.options.order === 'random') {
                            if (_this3.randomOrder.indexOf(_this3.list.index) < _this3.randomOrder.length - 1) {
                                _this3.list['switch'](_this3.nextIndex());
                                _this3.play();
                            } else {
                                _this3.list['switch'](_this3.nextIndex());
                                _this3.pause();
                            }
                        }
                    } else if (_this3.options.loop === 'one') {
                        _this3.list['switch'](_this3.list.index);
                        _this3.play();
                    } else if (_this3.options.loop === 'all') {
                        _this3.skipForward();
                        _this3.play();
                    }
                });
            }
        },
        {
            key: 'setAudio',
            value: function setAudio(audio) {
                if (this.hls) {
                    this.hls.destroy();
                    this.hls = null;
                }
                var type = audio.type;
                if (this.options.customAudioType && this.options.customAudioType[type]) {
                    if (Object.prototype.toString.call(this.options.customAudioType[type]) === '[object Function]') {
                        this.options.customAudioType[type](this.audio, audio, this);
                    } else {
                        console.error('Illegal customType: '.concat(type));
                    }
                } else {
                    if (!type || type === 'auto') {
                        if (/m3u8(#|\?|$)/i.exec(audio.url)) {
                            type = 'hls';
                        } else {
                            type = 'normal';
                        }
                    }
                    if (type === 'hls') {
                        if (window.Hls.isSupported()) {
                            this.hls = new window.Hls();
                            this.hls.loadSource(audio.url);
                            this.hls.attachMedia(this.audio);
                        } else if (this.audio.canPlayType('application/x-mpegURL') || this.audio.canPlayType('application/vnd.apple.mpegURL')) {
                            this.audio.src = audio.url;
                        } else {
                            this.notice('Error: HLS is not supported.');
                        }
                    } else if (type === 'normal') {
                        this.audio.src = audio.url;
                    }
                }
            }
        },
        {
            key: 'theme',
            value: function theme() {
                var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.list.audios[this.list.index].theme || this.options.theme;
                var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.list.index;
                var isReset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
                if (isReset) {
                    this.list.audios[index] && (this.list.audios[index].theme = color);
                }
                this.template.listCurs[index] && (this.template.listCurs[index].style.backgroundColor = color);
                if (index === this.list.index) {
                    this.template.pic.style.backgroundColor = color;
                    this.template.played.style.background = color;
                    this.template.thumb.style.background = color;
                    this.template.volume.style.background = color;
                }
            }
        },
        {
            key: 'seek',
            value: function seek(time) {
                time = Math.max(time, 0);
                time = Math.min(time, this.duration);
                this.audio.currentTime = time;
                this.bar.set('played', time / this.duration, 'width');
                this.template.ptime.innerHTML = utils.secondToTime(time);
            }
        },
        {
            key: 'duration',
            get: function get() {
                return isNaN(this.audio.duration) ? 0 : this.audio.duration;
            }
        },
        {
            key: 'setUIPlaying',
            value: function setUIPlaying() {
                var _this4 = this;
                if (this.paused) {
                    this.paused = false;
                    this.template.button.classList.remove('aplayer-play');
                    this.template.button.classList.add('aplayer-pause');
                    this.template.button.innerHTML = '';
                    setTimeout(function () {
                        _this4.template.button.innerHTML = Icons.pause;
                    }, 100);
                    this.template.skipPlayButton.innerHTML = Icons.pause;
                }
                this.timer.enable('loading');
                if (this.options.mutex) {
                    for (var i = 0; i < instances.length; i++) {
                        if (this !== instances[i]) {
                            instances[i].pause();
                        }
                    }
                }
            }
        },
        {
            key: 'play',
            value: function play() {
                var _this5 = this;
                this.setUIPlaying();
                var playPromise = this.audio.play();
                if (playPromise) {
                    playPromise['catch'](function (e) {
                        console.warn(e);
                        if (e.name === 'NotAllowedError') {
                            _this5.setUIPaused();
                        }
                    });
                }
                setTimeout(function () {
                    if (!_this5.paused) {
                        setTimeout(function () {
                            if (!_this5.paused) {
                                _this5.aplayerFlag = 6;
                                localStorage.setItem('aplayerFlag', '6');
                            }
                        }, 300);
                    }
                });
            }
        },
        {
            key: 'setUIPaused',
            value: function setUIPaused() {
                var _this6 = this;
                if (!this.paused) {
                    this.paused = true;
                    this.template.button.classList.remove('aplayer-pause');
                    this.template.button.classList.add('aplayer-play');
                    this.template.button.innerHTML = '';
                    setTimeout(function () {
                        _this6.template.button.innerHTML = Icons.play;
                    }, 100);
                    this.template.skipPlayButton.innerHTML = Icons.play;
                }
                this.container.classList.remove('aplayer-loading');
                this.timer.disable('loading');
            }
        },
        {
            key: 'pause',
            value: function pause() {
                this.setUIPaused();
                this.audio.pause();
            }
        },
        {
            key: 'switchVolumeIcon',
            value: function switchVolumeIcon() {
                if (this.volume() >= 0.95) {
                    this.template.volumeButton.innerHTML = Icons.volumeUp;
                } else if (this.volume() > 0) {
                    this.template.volumeButton.innerHTML = Icons.volumeDown;
                } else {
                    this.template.volumeButton.innerHTML = Icons.volumeOff;
                }
            }
        },
        {
            key: 'volume',
            value: function volume(percentage, nostorage) {
                percentage = parseFloat(percentage);
                if (!isNaN(percentage)) {
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    this.bar.set('volume', percentage, 'height');
                    if (!nostorage) {
                        this.storage.set('volume', percentage);
                    }
                    this.audio.volume = percentage;
                    if (this.audio.muted) {
                        this.audio.muted = false;
                    }
                    this.switchVolumeIcon();
                }
                return this.audio.muted ? 0 : this.audio.volume;
            }
        },
        {
            key: 'on',
            value: function on(name, callback) {
                this.events.on(name, callback);
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.template.button.classList.contains('aplayer-play')) {
                    this.play();
                } else if (this.template.button.classList.contains('aplayer-pause')) {
                    this.pause();
                }
            }
        },
        {
            key: 'switchAudio',
            value: function switchAudio(index) {
                this.list['switch'](index);
            }
        },
        {
            key: 'addAudio',
            value: function addAudio(audios) {
                this.list.add(audios);
            }
        },
        {
            key: 'removeAudio',
            value: function removeAudio(index) {
                this.list.remove(index);
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                instances.splice(instances.indexOf(this), 1);
                this.pause();
                this.container.innerHTML = '';
                this.audio.src = '';
                this.timer.destroy();
                this.events.trigger('destroy');
            }
        },
        {
            key: 'setMode',
            value: function setMode() {
                var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'normal';
                this.mode = mode;
                if (mode === 'mini') {
                    this.container.classList.add('aplayer-narrow');
                } else if (mode === 'normal') {
                    this.container.classList.remove('aplayer-narrow');
                }
            }
        },
        {
            key: 'notice',
            value: function notice(text) {
                var _this7 = this;
                var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
                var opacity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.8;
                this.template.notice.innerHTML = text;
                this.template.notice.style.opacity = opacity;
                if (this.noticeTime) {
                    clearTimeout(this.noticeTime);
                }
                this.events.trigger('noticeshow', { text: text });
                if (time) {
                    this.noticeTime = setTimeout(function () {
                        _this7.template.notice.style.opacity = 0;
                        _this7.events.trigger('noticehide');
                    }, time);
                }
            }
        },
        {
            key: 'prevIndex',
            value: function prevIndex() {
                if (this.list.audios.length > 1) {
                    if (this.options.order === 'list') {
                        return this.list.index - 1 < 0 ? this.list.audios.length - 1 : this.list.index - 1;
                    } else if (this.options.order === 'random') {
                        var index = this.randomOrder.indexOf(this.list.index);
                        if (index === 0) {
                            return this.randomOrder[this.randomOrder.length - 1];
                        } else {
                            return this.randomOrder[index - 1];
                        }
                    }
                } else {
                    return 0;
                }
            }
        },
        {
            key: 'nextIndex',
            value: function nextIndex() {
                if (this.list.audios.length > 1) {
                    if (this.options.order === 'list') {
                        return (this.list.index + 1) % this.list.audios.length;
                    } else if (this.options.order === 'random') {
                        var index = this.randomOrder.indexOf(this.list.index);
                        if (index === this.randomOrder.length - 1) {
                            return this.randomOrder[0];
                        } else {
                            return this.randomOrder[index + 1];
                        }
                    }
                } else {
                    return 0;
                }
            }
        },
        {
            key: 'skipBack',
            value: function skipBack() {
                this.list['switch'](this.prevIndex());
            }
        },
        {
            key: 'skipForward',
            value: function skipForward() {
                this.list['switch'](this.nextIndex());
            }
        },
        {
            key: 'isCloseWindowPage',
            value: function isCloseWindowPage() {
                var _this8 = this;
                var _this = this;
                window._this = _this;
                var pageLoadFlag = 0;
                window.onbeforeunload = function () {
                    setTimeout(function () {
                        var status = 'reload';
                        var flag = 66;
                        if (pageLoadFlag) {
                            status = 'close';
                            flag = 0;
                        }
                        if (_this.paused === false) {
                            localStorage.setItem('isPlayer', JSON.stringify({
                                play: true,
                                page: location.href,
                                time: _this.audio.currentTime,
                                status: status,
                                index: _this.list.index,
                                flag: flag
                            }));
                        }
                    });
                };
                window.addEventListener('unload', function () {
                    pageLoadFlag++;
                });
                setInterval(function () {
                    var isPlayer = localStorage.getItem('isPlayer');
                    var aplayerFlag = localStorage.getItem('aplayerFlag');
                    if (typeof _this8.aplayerFlag === 'number') {
                        _this8.aplayerFlag--;
                        if (_this8.aplayerFlag === 0) {
                            localStorage.setItem('aplayerFlag', '0');
                            _this8.aplayerFlag = null;
                        }
                    } else {
                        if (aplayerFlag !== null && aplayerFlag !== '0') {
                            _this8.aplayerFlag = aplayerFlag;
                            return _this8.pause();
                        }
                    }
                    if (isPlayer !== null) {
                        isPlayer = JSON.parse(isPlayer);
                        if (isPlayer.play && isPlayer.status !== 'play') {
                            if (isPlayer.flag) {
                                isPlayer.flag--;
                            } else {
                                isPlayer.play = false;
                                isPlayer.status = 'play';
                                isPlayer.index !== _this8.list.index && _this8.list['switch'](isPlayer.index);
                                setTimeout(function () {
                                    _this.audio.currentTime = isPlayer.time;
                                    _this.play();
                                }, 1000);
                            }
                            localStorage.setItem('isPlayer', JSON.stringify(isPlayer));
                        }
                    }
                }, 50);
            }
        }
    ], [{
            key: 'version',
            get: function get() {
                return APLAYER_VERSION;
            }
        }]);
    return APlayer;
}();
export default APlayer;