function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import utils from './utils';
var Storage = function () {
    function Storage(player) {
        _classCallCheck(this, Storage);
        this.storageName = player.options.storageName;
        this.data = JSON.parse(utils.storage.get(this.storageName));
        if (!this.data) {
            this.data = {};
        }
        this.data.volume = this.data.volume || player.options.volume;
    }
    _createClass(Storage, [
        {
            key: 'get',
            value: function get(key) {
                return this.data[key];
            }
        },
        {
            key: 'set',
            value: function set(key, value) {
                this.data[key] = value;
                utils.storage.set(this.storageName, JSON.stringify(this.data));
            }
        }
    ]);
    return Storage;
}();
export default Storage;