function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import tplListItem from '../template/list-item.art';
import utils from './utils';
import smoothScroll from 'smoothscroll';
var List = function () {
    function List(player) {
        _classCallCheck(this, List);
        this.player = player;
        this.index = 0;
        this.audios = this.player.options.audio;
        this.showing = true;
        this.player.template.list.style.height = ''.concat(Math.min(this.player.template.list.scrollHeight, this.player.options.listMaxHeight), 'px');
        this.bindEvents();
    }
    _createClass(List, [
        {
            key: 'bindEvents',
            value: function bindEvents() {
                var _this = this;
                this.player.template.list.addEventListener('click', function (e) {
                    var target;
                    if (e.target.tagName.toUpperCase() === 'LI') {
                        target = e.target;
                    } else {
                        target = e.target.parentElement;
                    }
                    var audioIndex = parseInt(target.getElementsByClassName('aplayer-list-index')[0].innerHTML) - 1;
                    if (audioIndex !== _this.index) {
                        _this['switch'](audioIndex);
                        _this.player.play();
                    } else {
                        _this.player.toggle();
                    }
                });
            }
        },
        {
            key: 'show',
            value: function show() {
                this.showing = true;
                this.player.template.list.scrollTop = this.index * 33;
                this.player.template.list.style.height = ''.concat(Math.min(this.player.template.list.scrollHeight, this.player.options.listMaxHeight), 'px');
                this.player.events.trigger('listshow');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                var _this2 = this;
                this.showing = false;
                this.player.template.list.style.height = ''.concat(Math.min(this.player.template.list.scrollHeight, this.player.options.listMaxHeight), 'px');
                setTimeout(function () {
                    _this2.player.template.list.style.height = '0px';
                    _this2.player.events.trigger('listhide');
                }, 0);
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.showing) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        },
        {
            key: 'add',
            value: function add(audios) {
                this.player.events.trigger('listadd', { audios: audios });
                if (Object.prototype.toString.call(audios) !== '[object Array]') {
                    audios = [audios];
                }
                audios.map(function (item) {
                    item.name = item.name || item.title || 'Audio name';
                    item.artist = item.artist || item.author || 'Audio artist';
                    item.cover = item.cover || item.pic;
                    item.type = item.type || 'normal';
                    return item;
                });
                var wasSingle = !(this.audios.length > 1);
                var wasEmpty = this.audios.length === 0;
                this.player.template.list.innerHTML += tplListItem({
                    theme: this.player.options.theme,
                    audio: audios,
                    index: this.audios.length + 1
                });
                this.audios = this.audios.concat(audios);
                if (wasSingle && this.audios.length > 1) {
                    this.player.container.classList.add('aplayer-withlist');
                }
                this.player.randomOrder = utils.randomOrder(this.audios.length);
                this.player.template.listCurs = this.player.container.querySelectorAll('.aplayer-list-cur');
                this.player.template.listCurs[this.audios.length - 1].style.backgroundColor = audios.theme || this.player.options.theme;
                if (wasEmpty) {
                    if (this.player.options.order === 'random') {
                        this['switch'](this.player.randomOrder[0]);
                    } else {
                        this['switch'](0);
                    }
                }
            }
        },
        {
            key: 'remove',
            value: function remove(index) {
                this.player.events.trigger('listremove', { index: index });
                if (this.audios[index]) {
                    if (this.audios.length > 1) {
                        var list = this.player.container.querySelectorAll('.aplayer-list li');
                        list[index].remove();
                        this.audios.splice(index, 1);
                        this.player.lrc && this.player.lrc.remove(index);
                        if (index === this.index) {
                            if (this.audios[index]) {
                                this['switch'](index);
                            } else {
                                this['switch'](index - 1);
                            }
                        }
                        if (this.index > index) {
                            this.index--;
                        }
                        for (var i = index; i < list.length; i++) {
                            list[i].getElementsByClassName('aplayer-list-index')[0].textContent = i;
                        }
                        if (this.audios.length === 1) {
                            this.player.container.classList.remove('aplayer-withlist');
                        }
                        this.player.template.listCurs = this.player.container.querySelectorAll('.aplayer-list-cur');
                    } else {
                        this.clear();
                    }
                }
            }
        },
        {
            key: 'switch',
            value: function _switch(index) {
                this.player.events.trigger('listswitch', { index: index });
                if (typeof index !== 'undefined' && this.audios[index]) {
                    this.index = index;
                    var audio = this.audios[this.index];
                    this.player.template.pic.style.backgroundImage = audio.cover ? 'url(\''.concat(audio.cover, '\')') : '';
                    this.player.theme(this.audios[this.index].theme || this.player.options.theme, this.index, false);
                    this.player.template.title.innerHTML = audio.name;
                    this.player.template.author.innerHTML = audio.artist ? ' - ' + audio.artist : '';
                    var light = this.player.container.getElementsByClassName('aplayer-list-light')[0];
                    if (light) {
                        light.classList.remove('aplayer-list-light');
                    }
                    this.player.container.querySelectorAll('.aplayer-list li')[this.index].classList.add('aplayer-list-light');
                    smoothScroll(this.index * 33, 500, null, this.player.template.list);
                    this.player.setAudio(audio);
                    this.player.lrc && this.player.lrc['switch'](this.index);
                    this.player.lrc && this.player.lrc.update(0);
                    if (this.player.duration !== 1) {
                        this.player.template.dtime.innerHTML = utils.secondToTime(this.player.duration);
                    }
                }
            }
        },
        {
            key: 'clear',
            value: function clear() {
                this.player.events.trigger('listclear');
                this.index = 0;
                this.player.container.classList.remove('aplayer-withlist');
                this.player.pause();
                this.audios = [];
                this.player.lrc && this.player.lrc.clear();
                this.player.audio.src = '';
                this.player.template.list.innerHTML = '';
                this.player.template.pic.style.backgroundImage = '';
                this.player.theme(this.player.options.theme, this.index, false);
                this.player.template.title.innerHTML = 'No audio';
                this.player.template.author.innerHTML = '';
                this.player.bar.set('loaded', 0, 'width');
                this.player.template.dtime.innerHTML = utils.secondToTime(0);
            }
        }
    ]);
    return List;
}();
export default List;