function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var Timer = function () {
    function Timer(player) {
        _classCallCheck(this, Timer);
        this.player = player;
        window.requestAnimationFrame = function () {
            return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        }();
        this.types = ['loading'];
        this.init();
    }
    _createClass(Timer, [
        {
            key: 'init',
            value: function init() {
                var _this = this;
                this.types.forEach(function (item) {
                    _this['init'.concat(item, 'Checker')]();
                });
            }
        },
        {
            key: 'initloadingChecker',
            value: function initloadingChecker() {
                var _this2 = this;
                var lastPlayPos = 0;
                var currentPlayPos = 0;
                var bufferingDetected = false;
                this.loadingChecker = setInterval(function () {
                    if (_this2.enableloadingChecker) {
                        currentPlayPos = _this2.player.audio.currentTime;
                        if (!bufferingDetected && currentPlayPos === lastPlayPos && !_this2.player.audio.paused) {
                            _this2.player.container.classList.add('aplayer-loading');
                            bufferingDetected = true;
                        }
                        if (bufferingDetected && currentPlayPos > lastPlayPos && !_this2.player.audio.paused) {
                            _this2.player.container.classList.remove('aplayer-loading');
                            bufferingDetected = false;
                        }
                        lastPlayPos = currentPlayPos;
                    }
                }, 100);
            }
        },
        {
            key: 'enable',
            value: function enable(type) {
                this['enable'.concat(type, 'Checker')] = true;
                if (type === 'fps') {
                    this.initfpsChecker();
                }
            }
        },
        {
            key: 'disable',
            value: function disable(type) {
                this['enable'.concat(type, 'Checker')] = false;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                var _this3 = this;
                this.types.forEach(function (item) {
                    _this3['enable'.concat(item, 'Checker')] = false;
                    _this3[''.concat(item, 'Checker')] && clearInterval(_this3[''.concat(item, 'Checker')]);
                });
            }
        }
    ]);
    return Timer;
}();
export default Timer;