import api from "!../../node_modules/_style-loader@2.0.0@style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/_css-loader@5.2.6@css-loader/dist/cjs.js??ref--5-1!../../node_modules/_postcss-loader@3.0.0@postcss-loader/src/index.js??ref--5-2!../../node_modules/_sass-loader@10.2.0@sass-loader/dist/cjs.js!./index.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};