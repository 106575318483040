function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
var Bar = function () {
    function Bar(template) {
        _classCallCheck(this, Bar);
        this.elements = {};
        this.elements.volume = template.volume;
        this.elements.played = template.played;
        this.elements.loaded = template.loaded;
    }
    _createClass(Bar, [
        {
            key: 'set',
            value: function set(type, percentage, direction) {
                percentage = Math.max(percentage, 0);
                percentage = Math.min(percentage, 1);
                this.elements[type].style[direction] = percentage * 100 + '%';
            }
        },
        {
            key: 'get',
            value: function get(type, direction) {
                return parseFloat(this.elements[type].style[direction]) / 100;
            }
        }
    ]);
    return Bar;
}();
export default Bar;