function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    return Constructor;
}
import utils from './utils';
import Icons from './icons';
var Controller = function () {
    function Controller(player) {
        _classCallCheck(this, Controller);
        this.player = player;
        this.initPlayButton();
        this.initPlayBar();
        this.initOrderButton();
        this.initLoopButton();
        this.initMenuButton();
        if (!utils.isMobile) {
            this.initVolumeButton();
        }
        this.initMiniSwitcher();
        this.initSkipButton();
        this.initLrcButton();
    }
    _createClass(Controller, [
        {
            key: 'initPlayButton',
            value: function initPlayButton() {
                var _this = this;
                this.player.template.pic.addEventListener('click', function () {
                    _this.player.toggle();
                });
            }
        },
        {
            key: 'initPlayBar',
            value: function initPlayBar() {
                var _this2 = this;
                var thumbMove = function thumbMove(e) {
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - _this2.player.template.barWrap.getBoundingClientRect().left) / _this2.player.template.barWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this2.player.bar.set('played', percentage, 'width');
                    _this2.player.lrc && _this2.player.lrc.update(percentage * _this2.player.duration);
                    _this2.player.template.ptime.innerHTML = utils.secondToTime(percentage * _this2.player.duration);
                };
                var thumbUp = function thumbUp(e) {
                    document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
                    document.removeEventListener(utils.nameMap.dragMove, thumbMove);
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - _this2.player.template.barWrap.getBoundingClientRect().left) / _this2.player.template.barWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this2.player.bar.set('played', percentage, 'width');
                    _this2.player.seek(percentage * _this2.player.duration);
                    _this2.player.disableTimeupdate = false;
                };
                this.player.template.barWrap.addEventListener(utils.nameMap.dragStart, function () {
                    _this2.player.disableTimeupdate = true;
                    document.addEventListener(utils.nameMap.dragMove, thumbMove);
                    document.addEventListener(utils.nameMap.dragEnd, thumbUp);
                });
            }
        },
        {
            key: 'initVolumeButton',
            value: function initVolumeButton() {
                var _this3 = this;
                this.player.template.volumeButton.addEventListener('click', function () {
                    if (_this3.player.audio.muted) {
                        _this3.player.volume(_this3.player.audio.volume, true);
                    } else {
                        _this3.player.audio.muted = true;
                        _this3.player.switchVolumeIcon();
                        _this3.player.bar.set('volume', 0, 'height');
                    }
                });
                var thumbMove = function thumbMove(e) {
                    var percentage = 1 - ((e.clientY || e.changedTouches[0].clientY) - _this3.player.template.volumeBar.getBoundingClientRect().top) / _this3.player.template.volumeBar.clientHeight;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this3.player.volume(percentage);
                };
                var thumbUp = function thumbUp(e) {
                    _this3.player.template.volumeBarWrap.classList.remove('aplayer-volume-bar-wrap-active');
                    document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
                    document.removeEventListener(utils.nameMap.dragMove, thumbMove);
                    var percentage = 1 - ((e.clientY || e.changedTouches[0].clientY) - _this3.player.template.volumeBar.getBoundingClientRect().top) / _this3.player.template.volumeBar.clientHeight;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this3.player.volume(percentage);
                };
                this.player.template.volumeBarWrap.addEventListener(utils.nameMap.dragStart, function () {
                    _this3.player.template.volumeBarWrap.classList.add('aplayer-volume-bar-wrap-active');
                    document.addEventListener(utils.nameMap.dragMove, thumbMove);
                    document.addEventListener(utils.nameMap.dragEnd, thumbUp);
                });
            }
        },
        {
            key: 'initOrderButton',
            value: function initOrderButton() {
                var _this4 = this;
                this.player.template.order.addEventListener('click', function () {
                    if (_this4.player.options.order === 'list') {
                        _this4.player.options.order = 'random';
                        _this4.player.template.order.innerHTML = Icons.orderRandom;
                    } else if (_this4.player.options.order === 'random') {
                        _this4.player.options.order = 'list';
                        _this4.player.template.order.innerHTML = Icons.orderList;
                    }
                });
            }
        },
        {
            key: 'initLoopButton',
            value: function initLoopButton() {
                var _this5 = this;
                this.player.template.loop.addEventListener('click', function () {
                    if (_this5.player.list.audios.length > 1) {
                        if (_this5.player.options.loop === 'one') {
                            _this5.player.options.loop = 'none';
                            _this5.player.template.loop.innerHTML = Icons.loopNone;
                        } else if (_this5.player.options.loop === 'none') {
                            _this5.player.options.loop = 'all';
                            _this5.player.template.loop.innerHTML = Icons.loopAll;
                        } else if (_this5.player.options.loop === 'all') {
                            _this5.player.options.loop = 'one';
                            _this5.player.template.loop.innerHTML = Icons.loopOne;
                        }
                    } else {
                        if (_this5.player.options.loop === 'one' || _this5.player.options.loop === 'all') {
                            _this5.player.options.loop = 'none';
                            _this5.player.template.loop.innerHTML = Icons.loopNone;
                        } else if (_this5.player.options.loop === 'none') {
                            _this5.player.options.loop = 'all';
                            _this5.player.template.loop.innerHTML = Icons.loopAll;
                        }
                    }
                });
            }
        },
        {
            key: 'initMenuButton',
            value: function initMenuButton() {
                var _this6 = this;
                this.player.template.menu.addEventListener('click', function () {
                    _this6.player.list.toggle();
                });
            }
        },
        {
            key: 'initMiniSwitcher',
            value: function initMiniSwitcher() {
                var _this7 = this;
                this.player.template.miniSwitcher.addEventListener('click', function () {
                    _this7.player.setMode(_this7.player.mode === 'mini' ? 'normal' : 'mini');
                });
            }
        },
        {
            key: 'initSkipButton',
            value: function initSkipButton() {
                var _this8 = this;
                this.player.template.skipBackButton.addEventListener('click', function () {
                    _this8.player.skipBack();
                });
                this.player.template.skipForwardButton.addEventListener('click', function () {
                    _this8.player.skipForward();
                });
                this.player.template.skipPlayButton.addEventListener('click', function () {
                    _this8.player.toggle();
                });
            }
        },
        {
            key: 'initLrcButton',
            value: function initLrcButton() {
                var _this9 = this;
                this.player.template.lrcButton.addEventListener('click', function () {
                    if (_this9.player.template.lrcButton.classList.contains('aplayer-icon-lrc-inactivity')) {
                        _this9.player.template.lrcButton.classList.remove('aplayer-icon-lrc-inactivity');
                        _this9.player.lrc && _this9.player.lrc.show();
                    } else {
                        _this9.player.template.lrcButton.classList.add('aplayer-icon-lrc-inactivity');
                        _this9.player.lrc && _this9.player.lrc.hide();
                    }
                });
            }
        }
    ]);
    return Controller;
}();
export default Controller;